<template>
  <v-container class="print-body" ref="reportElem">
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Erstellen sie eine Post auf der Startseite
        </p>
        <p class="text-center font-weight-light text-h4">
          Dieser wird für alle eingeloggten Nutzer sichtbar sein
        </p>
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <v-card class="pa-4">
          <v-card-title> Neuer Post </v-card-title>
          <v-card-text>
            <v-textarea
              label="Überschrift"
              auto-grow
              outlined
              rows="1"
              v-model="title"
              row-height="15"
            />
            <v-textarea
              label="Zweite Überschrift"
              auto-grow
              outlined
              rows="1"
              v-model="subtitle"
              row-height="15"
            />
            <v-textarea
              label="Inhalt"
              auto-grow
              outlined
              rows="5"
              v-model="content"
              row-height="15"
            />
            <v-file-input
              label="Beitrags Bild (Maximal 2MB)"
              prepend-icon="mdi-image"
              outlined
              v-model="imageToUpload"
            />
            <v-btn
              lass="mt-5 hide-at-print"
              color="primary"
              block
              @click="onUpload"
              label="Abschicken"
              >Abschicken</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
      imageToUpload: null,
      title: null,
      subtitle: null,
      content: null,
    };
  },
  methods: {
    onUpload() {
      const formData = new FormData();
      if (this.imageToUpload !== null) {
        formData.append("image", this.imageToUpload, this.imageToUpload.name);
      }
      formData.append("title", this.title);
      formData.append("subtitle", this.subtitle);
      formData.append("content", this.content);
      axios.post(
        this.$store.state.apiUrl + "/api/admin/postOnStartpage",
        formData
      );

      this.imageToUpload = null;
      this.title = null;
      this.subtitle = null;
      this.content = null;
    },
  },
};
</script>

<style></style>
